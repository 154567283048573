/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
	HttpClient,
	HttpHeaders,
	HttpParams,
	HttpResponse,
	HttpEvent,
	HttpParameterCodec,
	HttpContext,
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

// @ts-ignore
import { GetMyProfileResDto } from '../model/get-my-profile-res-dto';
// @ts-ignore
import { GetProfileByLinkResDto } from '../model/get-profile-by-link-res-dto';
// @ts-ignore
import { ListUsersPaginatedResDto } from '../model/list-users-paginated-res-dto';
// @ts-ignore
import { ListUsersResDto } from '../model/list-users-res-dto';
// @ts-ignore
import { Profile } from '../model/profile';
// @ts-ignore
import { User } from '../model/user';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

@Injectable({
	providedIn: 'root',
})
export class UsersService {
	protected basePath = 'http://localhost';
	public defaultHeaders = new HttpHeaders();
	public configuration = new Configuration();
	public encoder: HttpParameterCodec;

	constructor(
		protected httpClient: HttpClient,
		@Optional() @Inject(BASE_PATH) basePath: string | string[],
		@Optional() configuration: Configuration
	) {
		if (configuration) {
			this.configuration = configuration;
		}
		if (typeof this.configuration.basePath !== 'string') {
			if (Array.isArray(basePath) && basePath.length > 0) {
				basePath = basePath[0];
			}

			if (typeof basePath !== 'string') {
				basePath = this.basePath;
			}
			this.configuration.basePath = basePath;
		}
		this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
	}

	// @ts-ignore
	private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
		if (typeof value === 'object' && value instanceof Date === false) {
			httpParams = this.addToHttpParamsRecursive(httpParams, value);
		} else {
			httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
		}
		return httpParams;
	}

	private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
		if (value == null) {
			return httpParams;
		}

		if (typeof value === 'object') {
			if (Array.isArray(value)) {
				(value as any[]).forEach((elem) => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
			} else if (value instanceof Date) {
				if (key != null) {
					httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
				} else {
					throw Error('key may not be null if value is Date');
				}
			} else {
				Object.keys(value).forEach(
					(k) =>
						(httpParams = this.addToHttpParamsRecursive(
							httpParams,
							value[k],
							key != null ? `${key}.${k}` : k
						))
				);
			}
		} else if (key != null) {
			httpParams = httpParams.append(key, value);
		} else {
			throw Error('key may not be null if value is not object or array');
		}
		return httpParams;
	}

	/**
	 * @param userId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersAdminControllerDeleteUser(
		userId: string,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any>;
	public usersAdminControllerDeleteUser(
		userId: string,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpResponse<any>>;
	public usersAdminControllerDeleteUser(
		userId: string,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpEvent<any>>;
	public usersAdminControllerDeleteUser(
		userId: string,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error(
				'Required parameter userId was null or undefined when calling usersAdminControllerDeleteUser.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/admin/users/${this.configuration.encodeParam({
			name: 'userId',
			value: userId,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}`;
		return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param userId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersAdminControllerGetUser(
		userId: string,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<User>;
	public usersAdminControllerGetUser(
		userId: string,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<User>>;
	public usersAdminControllerGetUser(
		userId: string,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<User>>;
	public usersAdminControllerGetUser(
		userId: string,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error(
				'Required parameter userId was null or undefined when calling usersAdminControllerGetUser.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/admin/users/${this.configuration.encodeParam({
			name: 'userId',
			value: userId,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}`;
		return this.httpClient.request<User>('get', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param userId
	 * @param profileIndex
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersAdminControllerGetUserProfile(
		userId: string,
		profileIndex: string,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<Profile>;
	public usersAdminControllerGetUserProfile(
		userId: string,
		profileIndex: string,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<Profile>>;
	public usersAdminControllerGetUserProfile(
		userId: string,
		profileIndex: string,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<Profile>>;
	public usersAdminControllerGetUserProfile(
		userId: string,
		profileIndex: string,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error(
				'Required parameter userId was null or undefined when calling usersAdminControllerGetUserProfile.'
			);
		}
		if (profileIndex === null || profileIndex === undefined) {
			throw new Error(
				'Required parameter profileIndex was null or undefined when calling usersAdminControllerGetUserProfile.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/admin/users/${this.configuration.encodeParam({
			name: 'userId',
			value: userId,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}/${this.configuration.encodeParam({
			name: 'profileIndex',
			value: profileIndex,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}`;
		return this.httpClient.request<Profile>('get', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param sortField
	 * @param sortDirection
	 * @param search
	 * @param cardFilter
	 * @param orgIds
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersAdminControllerGetUsersList(
		sortField?: string,
		sortDirection?: 'asc' | 'desc',
		search?: string,
		cardFilter?: boolean,
		orgIds?: Array<string>,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<Array<ListUsersResDto>>;
	public usersAdminControllerGetUsersList(
		sortField?: string,
		sortDirection?: 'asc' | 'desc',
		search?: string,
		cardFilter?: boolean,
		orgIds?: Array<string>,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<Array<ListUsersResDto>>>;
	public usersAdminControllerGetUsersList(
		sortField?: string,
		sortDirection?: 'asc' | 'desc',
		search?: string,
		cardFilter?: boolean,
		orgIds?: Array<string>,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<Array<ListUsersResDto>>>;
	public usersAdminControllerGetUsersList(
		sortField?: string,
		sortDirection?: 'asc' | 'desc',
		search?: string,
		cardFilter?: boolean,
		orgIds?: Array<string>,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
		if (sortField !== undefined && sortField !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortField, 'sortField');
		}
		if (sortDirection !== undefined && sortDirection !== null) {
			localVarQueryParameters = this.addToHttpParams(
				localVarQueryParameters,
				<any>sortDirection,
				'sortDirection'
			);
		}
		if (search !== undefined && search !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>search, 'search');
		}
		if (cardFilter !== undefined && cardFilter !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>cardFilter, 'cardFilter');
		}
		if (orgIds) {
			orgIds.forEach((element) => {
				localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'orgIds');
			});
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/admin/users/list`;
		return this.httpClient.request<Array<ListUsersResDto>>('get', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			params: localVarQueryParameters,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param page
	 * @param pageSize
	 * @param sortField
	 * @param sortDirection
	 * @param search
	 * @param cardFilter
	 * @param orgIds
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersAdminControllerGetUsersPage(
		page: number,
		pageSize: number,
		sortField?: string,
		sortDirection?: 'asc' | 'desc',
		search?: string,
		cardFilter?: boolean,
		orgIds?: Array<string>,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<ListUsersPaginatedResDto>;
	public usersAdminControllerGetUsersPage(
		page: number,
		pageSize: number,
		sortField?: string,
		sortDirection?: 'asc' | 'desc',
		search?: string,
		cardFilter?: boolean,
		orgIds?: Array<string>,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<ListUsersPaginatedResDto>>;
	public usersAdminControllerGetUsersPage(
		page: number,
		pageSize: number,
		sortField?: string,
		sortDirection?: 'asc' | 'desc',
		search?: string,
		cardFilter?: boolean,
		orgIds?: Array<string>,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<ListUsersPaginatedResDto>>;
	public usersAdminControllerGetUsersPage(
		page: number,
		pageSize: number,
		sortField?: string,
		sortDirection?: 'asc' | 'desc',
		search?: string,
		cardFilter?: boolean,
		orgIds?: Array<string>,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (page === null || page === undefined) {
			throw new Error(
				'Required parameter page was null or undefined when calling usersAdminControllerGetUsersPage.'
			);
		}
		if (pageSize === null || pageSize === undefined) {
			throw new Error(
				'Required parameter pageSize was null or undefined when calling usersAdminControllerGetUsersPage.'
			);
		}

		let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
		if (page !== undefined && page !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'page');
		}
		if (pageSize !== undefined && pageSize !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pageSize, 'pageSize');
		}
		if (sortField !== undefined && sortField !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortField, 'sortField');
		}
		if (sortDirection !== undefined && sortDirection !== null) {
			localVarQueryParameters = this.addToHttpParams(
				localVarQueryParameters,
				<any>sortDirection,
				'sortDirection'
			);
		}
		if (search !== undefined && search !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>search, 'search');
		}
		if (cardFilter !== undefined && cardFilter !== null) {
			localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>cardFilter, 'cardFilter');
		}
		if (orgIds) {
			orgIds.forEach((element) => {
				localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'orgIds');
			});
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/admin/users`;
		return this.httpClient.request<ListUsersPaginatedResDto>(
			'get',
			`${this.configuration.basePath}${localVarPath}`,
			{
				context: localVarHttpContext,
				params: localVarQueryParameters,
				responseType: <any>responseType_,
				withCredentials: this.configuration.withCredentials,
				headers: localVarHeaders,
				observe: observe,
				reportProgress: reportProgress,
			}
		);
	}

	/**
	 * @param userId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersAdminControllerIsUserExists(
		userId: string,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<boolean>;
	public usersAdminControllerIsUserExists(
		userId: string,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<boolean>>;
	public usersAdminControllerIsUserExists(
		userId: string,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<boolean>>;
	public usersAdminControllerIsUserExists(
		userId: string,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error(
				'Required parameter userId was null or undefined when calling usersAdminControllerIsUserExists.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/admin/users/${this.configuration.encodeParam({
			name: 'userId',
			value: userId,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}/exists`;
		return this.httpClient.request<boolean>('get', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param userId
	 * @param user
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersAdminControllerUpdateUser(
		userId: string,
		user: User,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any>;
	public usersAdminControllerUpdateUser(
		userId: string,
		user: User,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpResponse<any>>;
	public usersAdminControllerUpdateUser(
		userId: string,
		user: User,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpEvent<any>>;
	public usersAdminControllerUpdateUser(
		userId: string,
		user: User,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error(
				'Required parameter userId was null or undefined when calling usersAdminControllerUpdateUser.'
			);
		}
		if (user === null || user === undefined) {
			throw new Error(
				'Required parameter user was null or undefined when calling usersAdminControllerUpdateUser.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		// to determine the Content-Type header
		const consumes: string[] = ['application/json'];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/admin/users/${this.configuration.encodeParam({
			name: 'userId',
			value: userId,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}`;
		return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			body: user,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param userId
	 * @param profileIndex
	 * @param profile
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersAdminControllerUpdateUserProfile(
		userId: string,
		profileIndex: string,
		profile: Profile,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any>;
	public usersAdminControllerUpdateUserProfile(
		userId: string,
		profileIndex: string,
		profile: Profile,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpResponse<any>>;
	public usersAdminControllerUpdateUserProfile(
		userId: string,
		profileIndex: string,
		profile: Profile,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpEvent<any>>;
	public usersAdminControllerUpdateUserProfile(
		userId: string,
		profileIndex: string,
		profile: Profile,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any> {
		if (userId === null || userId === undefined) {
			throw new Error(
				'Required parameter userId was null or undefined when calling usersAdminControllerUpdateUserProfile.'
			);
		}
		if (profileIndex === null || profileIndex === undefined) {
			throw new Error(
				'Required parameter profileIndex was null or undefined when calling usersAdminControllerUpdateUserProfile.'
			);
		}
		if (profile === null || profile === undefined) {
			throw new Error(
				'Required parameter profile was null or undefined when calling usersAdminControllerUpdateUserProfile.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		// to determine the Content-Type header
		const consumes: string[] = ['application/json'];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/admin/users/${this.configuration.encodeParam({
			name: 'userId',
			value: userId,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}/${this.configuration.encodeParam({
			name: 'profileIndex',
			value: profileIndex,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}`;
		return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			body: profile,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersControllerDeleteMyUser(
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any>;
	public usersControllerDeleteMyUser(
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpResponse<any>>;
	public usersControllerDeleteMyUser(
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpEvent<any>>;
	public usersControllerDeleteMyUser(
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any> {
		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/user`;
		return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersControllerGetMyUser(
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<User>;
	public usersControllerGetMyUser(
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<User>>;
	public usersControllerGetMyUser(
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<User>>;
	public usersControllerGetMyUser(
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/user`;
		return this.httpClient.request<User>('get', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param profileIndex
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersControllerGetMyUserProfile(
		profileIndex: string,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<GetMyProfileResDto>;
	public usersControllerGetMyUserProfile(
		profileIndex: string,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<GetMyProfileResDto>>;
	public usersControllerGetMyUserProfile(
		profileIndex: string,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<GetMyProfileResDto>>;
	public usersControllerGetMyUserProfile(
		profileIndex: string,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (profileIndex === null || profileIndex === undefined) {
			throw new Error(
				'Required parameter profileIndex was null or undefined when calling usersControllerGetMyUserProfile.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/user/${this.configuration.encodeParam({
			name: 'profileIndex',
			value: profileIndex,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}`;
		return this.httpClient.request<GetMyProfileResDto>('get', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param linkId
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersControllerGetProfileByLink(
		linkId: string,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<GetProfileByLinkResDto>;
	public usersControllerGetProfileByLink(
		linkId: string,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<GetProfileByLinkResDto>>;
	public usersControllerGetProfileByLink(
		linkId: string,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<GetProfileByLinkResDto>>;
	public usersControllerGetProfileByLink(
		linkId: string,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (linkId === null || linkId === undefined) {
			throw new Error(
				'Required parameter linkId was null or undefined when calling usersControllerGetProfileByLink.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/user/links/${this.configuration.encodeParam({
			name: 'linkId',
			value: linkId,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}`;
		return this.httpClient.request<GetProfileByLinkResDto>('get', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param profileIndex
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersControllerGetSignatureForProfile(
		profileIndex: string,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<string>;
	public usersControllerGetSignatureForProfile(
		profileIndex: string,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpResponse<string>>;
	public usersControllerGetSignatureForProfile(
		profileIndex: string,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<HttpEvent<string>>;
	public usersControllerGetSignatureForProfile(
		profileIndex: string,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
	): Observable<any> {
		if (profileIndex === null || profileIndex === undefined) {
			throw new Error(
				'Required parameter profileIndex was null or undefined when calling usersControllerGetSignatureForProfile.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = ['application/json'];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/user/signature/${this.configuration.encodeParam({
			name: 'profileIndex',
			value: profileIndex,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}`;
		return this.httpClient.request<string>('get', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param user
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersControllerUpdateMyUser(
		user: User,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any>;
	public usersControllerUpdateMyUser(
		user: User,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpResponse<any>>;
	public usersControllerUpdateMyUser(
		user: User,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpEvent<any>>;
	public usersControllerUpdateMyUser(
		user: User,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any> {
		if (user === null || user === undefined) {
			throw new Error('Required parameter user was null or undefined when calling usersControllerUpdateMyUser.');
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		// to determine the Content-Type header
		const consumes: string[] = ['application/json'];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/user`;
		return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			body: user,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}

	/**
	 * @param profileIndex
	 * @param profile
	 * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
	 * @param reportProgress flag to report request and response progress.
	 */
	public usersControllerUpdateMyUserProfile(
		profileIndex: string,
		profile: Profile,
		observe?: 'body',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any>;
	public usersControllerUpdateMyUserProfile(
		profileIndex: string,
		profile: Profile,
		observe?: 'response',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpResponse<any>>;
	public usersControllerUpdateMyUserProfile(
		profileIndex: string,
		profile: Profile,
		observe?: 'events',
		reportProgress?: boolean,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<HttpEvent<any>>;
	public usersControllerUpdateMyUserProfile(
		profileIndex: string,
		profile: Profile,
		observe: any = 'body',
		reportProgress: boolean = false,
		options?: { httpHeaderAccept?: undefined; context?: HttpContext }
	): Observable<any> {
		if (profileIndex === null || profileIndex === undefined) {
			throw new Error(
				'Required parameter profileIndex was null or undefined when calling usersControllerUpdateMyUserProfile.'
			);
		}
		if (profile === null || profile === undefined) {
			throw new Error(
				'Required parameter profile was null or undefined when calling usersControllerUpdateMyUserProfile.'
			);
		}

		let localVarHeaders = this.defaultHeaders;

		let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
		if (localVarHttpHeaderAcceptSelected === undefined) {
			// to determine the Accept header
			const httpHeaderAccepts: string[] = [];
			localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
		}
		if (localVarHttpHeaderAcceptSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
		}

		let localVarHttpContext: HttpContext | undefined = options && options.context;
		if (localVarHttpContext === undefined) {
			localVarHttpContext = new HttpContext();
		}

		// to determine the Content-Type header
		const consumes: string[] = ['application/json'];
		const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
		if (httpContentTypeSelected !== undefined) {
			localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
		}

		let responseType_: 'text' | 'json' | 'blob' = 'json';
		if (localVarHttpHeaderAcceptSelected) {
			if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
				responseType_ = 'text';
			} else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
				responseType_ = 'json';
			} else {
				responseType_ = 'blob';
			}
		}

		let localVarPath = `/user/${this.configuration.encodeParam({
			name: 'profileIndex',
			value: profileIndex,
			in: 'path',
			style: 'simple',
			explode: false,
			dataType: 'string',
			dataFormat: undefined,
		})}`;
		return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`, {
			context: localVarHttpContext,
			body: profile,
			responseType: <any>responseType_,
			withCredentials: this.configuration.withCredentials,
			headers: localVarHeaders,
			observe: observe,
			reportProgress: reportProgress,
		});
	}
}

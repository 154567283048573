/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Signature {
	userId?: string;
	profileId?: Signature.ProfileIdEnum;
	firstName?: boolean;
	lastName?: boolean;
	homeAddress?: boolean;
	cellPhone?: boolean;
	cellPhone2?: boolean;
	cellPhone3?: boolean;
	cellPhone4?: boolean;
	whatsapp?: boolean;
	email?: boolean;
	email2?: boolean;
	email3?: boolean;
	url?: boolean;
	birthday?: boolean;
	organization?: boolean;
	orgAddress?: boolean;
	orgTaxNumber?: boolean;
	orgRegistrationNumber?: boolean;
	chatBot?: boolean;
	questionnaire?: boolean;
	title?: boolean;
	titleNew?: boolean;
	profileURL?: boolean;
	videoURL?: boolean;
	publicLink?: boolean;
	facebook?: boolean;
	facebook_business?: boolean;
	youtube?: boolean;
	instagram?: boolean;
	instagram_business?: boolean;
	tiktok?: boolean;
	linkedIn?: boolean;
	linkedIn_business?: boolean;
	twitter?: boolean;
	snapchat?: boolean;
	flickr?: boolean;
	skype?: boolean;
	pinterest?: boolean;
	vimeo?: boolean;
	patreon?: boolean;
	imdb?: boolean;
	soundcloud?: boolean;
	spotify?: boolean;
	mnbNumber?: boolean;
	profileTheme?: boolean;
}
export namespace Signature {
	export type ProfileIdEnum = 'primary' | '0' | '1';
	export const ProfileIdEnum = {
		Primary: 'primary' as ProfileIdEnum,
		_0: '0' as ProfileIdEnum,
		_1: '1' as ProfileIdEnum,
	};
}

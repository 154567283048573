/**
 * Backend API
 * Card In Move
 *
 * The version of the OpenAPI document: 4.0.2
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NfcTag } from './nfc-tag';

export interface NfcCard {
	id?: string;
	cardID: string;
	cardInfo?: NfcTag;
	url: string;
	createdAt: string;
	linkID: string;
	userID: string;
	email: string;
	isReferralBonusEnabled?: boolean;
	disabled: boolean;
}

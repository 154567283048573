import { Profile } from "../modules/api";
import { SocialElement } from "./social-element.model";

export const socialElements: SocialElement[] = [
    {
        type: 'facebook',
        img: 'assets/icon/icons-social/facebook.svg',
        field: 'facebook',
    },
    {
        type: 'facebook_business',
        img: 'assets/icon/icons-social/facebook-business.svg',
        field: 'facebook_business',
    },
    {
        type: 'youtube',
        img: 'assets/icon/icons-social/youtube.svg',
        field: 'youtube',
    },
    {
        type: 'instagram',
        img: 'assets/icon/icons-social/instagram.svg',
        field: 'instagram',
    },
    {
        type: 'instagram_business',
        img: 'assets/icon/icons-social/instagram-business.svg',
        field: 'instagram_business',
    },
    {
        type: 'tiktok',
        img: 'assets/icon/icons-social/tiktok.svg',
        field: 'tiktok',
    },
    {
        type: 'linkedin',
        img: 'assets/icon/icons-social/linkedin.svg',
        field: 'linkedIn',
    },
    {
        type: 'linkedin_business',
        img: 'assets/icon/icons-social/linkedin-business.svg',
        field: 'linkedIn_business',
    },
    {
        type: 'twitter',
        img: 'assets/icon/icons-social/twitter.svg',
        field: 'twitter',
    },
    {
        type: 'snapchat',
        img: 'assets/icon/icons-social/snapchat.svg',
        field: 'snapchat',
    },
    {
        type: 'skype',
        img: 'assets/icon/icons-social/skype.svg',
        field: 'skype',
        showLabel: (p: Profile) => `${p.firstName} ${p.lastName}`,
    },
    {
        type: 'pinterest',
        img: 'assets/icon/icons-social/pinterest.svg',
        field: 'pinterest',
    },
    {
        type: 'vimeo',
        img: 'assets/icon/icons-social/vimeo.svg',
        field: 'vimeo',
    },
    {
        type: 'patreon',
        img: 'assets/icon/icons-social/patreon.svg',
        field: 'patreon',
    },
    {
        type: 'imdb',
        img: 'assets/icon/icons-social/imdb.svg',
        field: 'imdb',
    },
    {
        type: 'soundcloud',
        img: 'assets/icon/icons-social/logo-soundcloud.svg',
        field: 'soundcloud',
    },
    {
        type: 'spotify',
        img: 'assets/icon/icons-social/spotify.svg',
        field: 'spotify',
    },
];
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthsService } from '../../../../services/auths.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
	constructor(
		private authService: AuthsService,
		private router: Router,
		private snackBar: MatSnackBar,
		private translateService: TranslateService
	) {
		this.selectedLanguage = this.translateService.currentLang;
		this.translateService.onLangChange.subscribe(() => {
			this.selectedLanguage = this.translateService.currentLang;
		});
	}

	selectedLanguage: string;
	currentYear: number = new Date().getFullYear();

	isAuthenticated(): boolean {
		return this.authService.isAuthenticated();
	}

	logout(): void {
		this.authService
			.logout()
			.then(() => this.router.navigate(['/']))
			.catch((e) => this.snackBar.open(e.message, 'X', { panelClass: 'error' }));
	}

	goToShopping() {
		window.open('https://cardinmove.com/termekek/', '_blank');
	}

	onLanguageChange(event: MatButtonToggleChange) {
		this.translateService.use(event.value);
	}
}

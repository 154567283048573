<nav class="navbar">
  <div class="nav-container">
    <div class="logo-container">
      <div class="d-flex d-flex-row justify-content-center w-full align-items-center pb-2">
        <mat-button-toggle-group name="fontStyle" [(ngModel)]="selectedLanguage" (change)="onLanguageChange($event)" aria-label="Font Style">
          <mat-button-toggle value="hu">HU</mat-button-toggle>
          <mat-button-toggle value="en">EN</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div>
        <a [routerLink]="['']" class="logo">
          <img class="nav-logo" src="assets/cim-logo-icon-black.svg" />
          <p>Card In Move</p>
        </a>
      </div>
      <div class="copyright">
        Sales In Move Zrt © 2022-{{ currentYear }} <br />
        {{ 'shared.footer.copyright' | translate }}
      </div>
    </div>

    <ul *ngIf="isAuthenticated()" class="nav-list">
      <li class="nav-item">
        <button mat-button [routerLink]="'/profile'">
          <img class="me-2" src="assets/icon/icons-tertiary/account_circle.svg" />
          {{ 'shared.header.my-profile' | translate }}
        </button>
      </li>
      <li class="nav-item">
        <button mat-button (click)="goToShopping()">
          <img class="me-2" src="assets/icon/icons-tertiary/credit_card.svg" />
          {{'shared.header.shop' | translate }}
        </button>
      </li>
    </ul>

    <ul *ngIf="isAuthenticated()" class="nav-list">
      <li *hasAnyAuthorityLevel="'admin'" class="nav-item">
        <button mat-button [routerLink]="'/companies'">
          <img class="me-2" src="assets/icon/icons-tertiary/adjust.svg" />
          {{'shared.header.companies' | translate }}
        </button>
      </li>
      <li *hasAnyAuthorityLevel="'admin'" class="nav-item">
        <button mat-button [routerLink]="'/users'">
          <img class="me-2" src="assets/icon/icons-tertiary/supervised_user_circle.svg" />
          {{'shared.header.users' | translate }}
        </button>
      </li>
      <li *hasAnyResourceAuthority="{ resource: '*', authorities: 'admin' }" class="nav-item">
        <button mat-button [routerLink]="'/orders'">
          <img class="me-2" src="assets/icon/icons-tertiary/trending_up.svg" />
          {{ 'shared.header.orders' | translate }}
        </button>
      </li>
      <li *hasAnyResourceAuthority="{ resource: '*', authorities: 'admin' }" class="nav-item">
        <button mat-button [routerLink]="'/payouts'">
          <img class="me-2" src="assets/icon/icons-tertiary/credit_card.svg" />
          {{ 'shared.header.payouts' | translate }}
        </button>
      </li>
      <li *hasAnyResourceAuthority="{ resource: 'system', authorities: ['admin', 'editor', 'member'] }"
        class="nav-item">
        <button mat-button [routerLink]="'/system/i18n'">
          <img class="me-2" src="assets/icon/icons-tertiary/language.svg" />
          {{'shared.header.system-settings.i18n' | translate }}
        </button>
      </li>
    </ul>

    <ul *ngIf="!isAuthenticated()" class="nav-list">
      <li class="nav-item">
        <button mat-button [routerLink]="'/auth/register'">{{ 'shared.header.sign-up' | translate }}</button>
      </li>
      <li class="nav-item">
        <button mat-button [routerLink]="'/auth/login'">{{ 'shared.header.log-in' | translate }}</button>
      </li>
    </ul>

    <ul *ngIf="isAuthenticated()" class="nav-list">
      <li class="nav-item">
        <button mat-button (click)="logout()">
          <img class="me-2" src="assets/icon/icons-tertiary/logout.svg" />
          {{ 'shared.header.log-out' | translate }}
        </button>
      </li>
    </ul>
  </div>
</nav>